import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import './assets/less/allcss.less'
import 'amfe-flexible/index.js'
import * as echarts from 'echarts';
import VueClipboard from 'vue-clipboard2'
import i18n from './lang/index.js'

Vue.use(VueClipboard)
Vue.use(Vant);
Vue.config.productionTip = false;
Vue.prototype.$echarts = echarts;

router.beforeEach((to, from, next) => {
  var token = localStorage.getItem('token');
  //如果没登录,都导向登录页
  if (!token) {
    if (to.path !== '/login' && to.path !== '/language' && to.path !== '/register') {
      next({ path: '/login' })
    }
    else {
      next();
    }
  }
  else {
    next();
  }
})

// router.beforeEach((to, from, next) => {
//   var whiteList = ['/login', '/register','/language']
//   var token = localStorage.getItem('token');
//   router.beforeEach((to, from, next) => {
//     // 判断当前路由是否在白名单中
//     if (whiteList.indexOf(to.path) !== -1) {
//       console.log(1)
//       // 如果在白名单中，则直接放行
//       next()
//     } else {
//       console.log(2)
//       // 如果不在白名单中，则需要进行鉴权
//       next({ path: '/login' })
//     }
//   })
  
// })

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
