import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    checked: '',
    info:{},
    service:'',
    popstatus:0
  },
  getters: {
  },
  mutations: {
    changeChecked(state,value) {
      state.checked = value
      // console.log(state.checked)
    },
    Updateinfo(state,value){
      state.info = value;
      // console.log(state.info)
    },
    Upservice(state,value){
      state.service = value;
    },
    Uppopstatus(state,value){
      state.popstatus = value;
    },
  },
  actions: {
  },
  modules: {
  }
})
