<template>
  <div class="home">
    <div class="Header">
      <div class="Header-content">
        <div class="menu" @click="Show()">
          <img v-if="$store.state.checked" src="../assets/images/menuW.png" alt="" />
          <img v-else src="../assets/images/menuB.png" alt="" />
        </div>
        <div class="connect-wallet" v-if="info && info.userId">ID:{{info.userId}}</div>
      </div>
    </div>
    <div class="content">
      <div class="banner">
        <van-swipe :autoplay="3000" height="150" indicator-color="#fff">
          <van-swipe-item v-for="(item, index) in bannerList" :key="index">
            <img v-lazy="item.image" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="hot_flex">
        <div class="all-title">{{$t('lange.HotTitle')}}</div>
        <div class="ftxPage">
          <div class="grid-item" v-for="(item,index) in hotTickersList" :key="index" @click="alljump(item)">
            <div class="grid-item-content">
              <div class="grid-item-list">
                <span class="items-end"
                  >{{item.name}}<span class="follow-item-small">/USDT</span>
                </span>
                <img :src="item.icon" alt="" />
              </div>
              <div class="textNum" :class="item.changePercent > 0 ? 'green' : 'red'">{{item.price}}</div>
              <div class="textBi" :class="item.changePercent > 0 ? 'green' : 'red'">{{item.changePercent}}%</div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="all-title">{{$t('lange.fexDataTitle')}}</div>
        <div class="coinListItem">
          <div class="item" v-for="(item,index) in TickersList" :key="index" @click="alljump(item)">
            <div class="item-left">
              <img :src="item.icon" alt="" />
              <span class="num">{{item.name}}/<span class="vol">USDT</span> </span>
            </div>
            <div class="item-center red">
              <p class="num">{{item.price}}</p>
              <p class="vol">Vol{{(item.volume / 1000).toFixed(2)}}K</p>
            </div>
            <div class="item-right">
              <div class="btn" :class="item.changePercent > 0 ? 'greenbg' : 'redbg'">{{item.changePercent}}%</div>
            </div>
          </div>
        </div>
      </div>
      <div class="list-intro">
        <div class="all-title">
          {{$t('lange.platformTitle')}}
        </div>
        <ul class="">
          <li class="item-li">
            <img src="../assets/images/a1.png" alt="logo" class="img" />
            <div class="">
              <h2 class="SafuTitle">{{$t('lange.SafuTitle')}}</h2>
              <p class="SafuContent">
                {{$t('lange.SafuContent')}}
              </p>
            </div>
          </li>
          <li class="item-li">
            <img src="../assets/images/a2.png" alt="logo" class="img" />
            <div class="">
              <h2 class="SafuTitle">{{$t('lange.PersonalizedTitle')}}</h2>
              <p class="SafuContent">
                {{$t('lange.PersonaContent')}}
              </p>
            </div>
          </li>
          <li class="item-li">
            <img src="../assets/images/a3.png" alt="logo" class="img" />
            <div class="">
              <h2 class="SafuTitle"> {{$t('lange.AdvancedTitle')}}</h2>
              <p class="SafuContent">
                 {{$t('lange.AdvancedContent')}}
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div class="list-faq">
        <div class="all-title">{{$t('lange.questionTitle')}}</div>
        <div class="collapse">
          <van-collapse v-model="activeNames">
            <van-collapse-item
              :title="$t('lange.questionList_c1')"
              name="1"
              >{{$t('lange.questionList_b1')}}</van-collapse-item
            >
            <van-collapse-item
              :title="$t('lange.questionList_c2')"
              name="2"
              >{{$t('lange.questionList_b2')}}</van-collapse-item
            >
            <van-collapse-item
              :title="$t('lange.questionList_c3')"
              name="3"
              >{{$t('lange.questionList_b3')}}</van-collapse-item
            >
            <van-collapse-item
              :title="$t('lange.questionList_c4')"
              name="4"
              ><div class="p-box" v-html="$t('lange.questionList_b4')"></div></van-collapse-item
            >
          </van-collapse>
        </div>
      </div>
      <div class="list-dept">
        <div class="all-title">{{$t('lange.ContractTitle')}}</div>
        <div class="Contract_img">
          <img src="../assets/images/top-left.png" alt="" />
          <img src="../assets/images/top-right.png" alt="" />
        </div>
        <div class="Contract_text">
          <div>
            <img src="../assets/images/c1.png" alt="" />
            <p>{{$t('lange.SpotTitle')}}</p>
          </div>
          <div>
            <img src="../assets/images/c2.png" alt="" />
            <p>{{$t('lange.StakingTitle')}}</p>
          </div>
        </div>
        <div class="trade">
          <div class="title">{{$t('lange.StartTitle')}}</div>
          <p class="text">{{$t('lange.DecentralizedTitle')}}</p>
          <div class="btn-padding" @click="tradjump()">
            <div class="btn">{{$t('lange.TradeNowTitle')}}</div>
          </div>
        </div>
      </div>
      <div class="list-partener">
        <div class="all-title">{{$t('lange.PartnerTitle')}}</div>
        <div class="item-img-box">
          <div class="item-img" v-for="(item,index) in listImg" :key="index">
            <img :src="item" alt="" />
          </div>
        </div>
      </div>
    </div>
    <HelloWorld ref="showPopup"></HelloWorld>
  </div>
</template>

<script>
import {hotTickers,tickers,banner} from "../request/api";
import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import { Lazyload } from "vant";

Vue.use(Lazyload);
export default {
  // name: 'HomeView',
  components: {
    HelloWorld,
  },
  data() {
    return {
      info:{},
      images: [
        "https://img01.yzcdn.cn/vant/apple-1.jpg",
        "https://img01.yzcdn.cn/vant/apple-2.jpg",
      ],
      activeNames: [],
      listImg: [
        require("../assets/images/Partner1.png"),
        require("../assets/images/Partner2.png"),
        require("../assets/images/Partner3.png"),
        require("../assets/images/Partner4.png"),
        require("../assets/images/Partner5.png"),
        require("../assets/images/Partner6.png"),
        require("../assets/images/Partner7.png"),
        require("../assets/images/Partner8.png"),
        require("../assets/images/Partner9.png"),
      ],
      hotTickersList:[],
      TickersList:[],
      timer : null,
      bannerList:[]
    };
  },
    watch: {
    jtinfo: {
      handler(nval) {
        this.info = nval;
      },
      deep: true, // 深度监听
      immediate: true, //立即执行
    },
  },
   computed: {
    jtinfo() {
      return this.info
    }
   },
  created(){
    // this.gethotTickers()
    // this.getTickers()
    this.info = this.$store.state.info == '{}'  ? this.$store.state.info : JSON.parse(localStorage.getItem('info'))
    let data = {
      page:1,
      pageSize:20
    }
    banner(data).then((res) =>{
      // console.log(res)
      this.bannerList = res.data.data
    })
  },
  mounted(){
    setTimeout(()=>{
      this.info = this.$store.state.info == '{}'  ? this.$store.state.info : JSON.parse(localStorage.getItem('info'))
      // console.log(this.info)
    },1000)
        this.gethotTickers()
    this.getTickers()
    this.timer = setInterval(()=>{
        this.gethotTickers()
        this.getTickers()
    },2000)
  },
  beforeDestroy(){
    clearInterval(this.timer)
  },
  methods: {
    alljump(item){
      this.$router.push({ path: "trade", query: {name:item.name} });
    },
    tradjump(){
      this.$router.push({ path: "trade", query: {} });
    },
    gethotTickers(){
      hotTickers().then((res) =>{
        // console.log(res.data)
        this.hotTickersList = res.data
      })
    },
    getTickers(){
      let data = {page:1,pageSize:99}
      tickers(data).then((res) =>{
        // console.log(res.data.data)
        this.TickersList = res.data.data
      })
    },
    Show() {
      this.$refs.showPopup.showPopup();
    },
  },
};
</script>
<style lang="less">
.home {
  width: 100%;
  padding-bottom: 50PX;
  .Header {
    width: 100%;
    box-sizing: border-box;
    z-index: 10;
    background: #fafef8;
    // background: #000;
    position: fixed;
    .Header-content {
      padding: 26px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .menu {
        img {
          width: 44px;
          height: 38px;
          display: block;
          vertical-align: middle;
        }
      }
      .connect-wallet {
        font-size: 24px;
        padding: 6px 18px;
        background: #a0abc1;
        font-weight: 400;
        border-radius: 6px;
        color: #fff;
        margin-right: 24px;
        // line-height: normal;
      }
    }
  }
  .content {
    padding-top: 50PX;
  }
  .banner {
    // height: 300px;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .ftxPage {
    display: flex;
    flex-wrap: wrap;
    .grid-item {
      position: relative;
      box-sizing: border-box;
      flex-basis: 50%;
      &-content {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        height: 100%;
        padding: 10px 8px;
        background-color: #fafef8;
        .textNum {
          font-weight: bold;
          font-size: 32px;
          text-align: left;
          padding-left: 16px;
        }
        .textBi {
          font-size: 28px;
          text-align: left;
          padding-left: 16px;
        }
        .green {
          color: #35b65a;
        }
        .red {
          color: #ff494a;
        }
        :after {
          position: absolute;
          box-sizing: border-box;
          content: " ";
          pointer-events: none;
          top: -50%;
          right: -50%;
          bottom: -50%;
          left: -50%;
          border: 0 solid #ebedf0;
          -webkit-transform: scale(0.5);
          transform: scale(0.5);
          z-index: 1;
          border-width: 0 1px 1px 0;
        }
      }
      &-list {
        padding: 0 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .items-end {
          font-size: 32px;
          font-weight: bold;
          display: flex;
          align-items: flex-end;
          .follow-item-small {
            font-size: 22px;
            color: #777;
            font-weight: 600;
            margin-left: 8px;
          }
        }
        img {
          width: 36px;
          height: 36px;
          display: block;
        }
      }
    }
  }
  .container {
    .item {
      display: flex;
      align-items: center;
      border-top: 2px solid rgb(238, 238, 238);
      background: #fafef8;
      padding: 20px 26px;
      font-size: 28px;
      justify-content: space-between;
      .item-left {
        display: flex;
        align-items: center;
        width: 240px;
        .num {
          font-weight: bold;
        }
        .vol {
          color: #777;
          font-weight: 100;
          font-size: 24px;
        }
        img {
          width: 50px;
          height: 50px;
          display: block;
          margin-right: 10px;
        }
      }
      .item-center {
        .num {
          font-weight: bold;
          font-size: 30px;
        }
        .vol {
          font-size: 20px;
          font-weight: bold;
          color: rgb(170, 170, 170);
        }
      }
      .item-right {
        width: 200px;
        display: flex;
        justify-content: end;
        .btn {
          width: 150px;
          height: 64px;
          line-height: 64px;
          font-size: 32px;
          color: #fff;
          border-radius: 10px;
          text-align: center;
        }
      }
      .green {
        color: #35b65a;
      }
      .red {
        color: #ff494a;
      }
      .greenbg {
        background: #2ebd85;
      }
      .redbg {
        background: #f6465d;
      }
    }
  }
  .list-intro {
    .item-li {
      display: flex;
      align-items: center;
      border-bottom: 2px solid #e5e7ed;
      padding: 50px 26px;
      font-size: 28px;
      background: #fafef8;
      .SafuTitle {
        margin: 0;
        font-size: 28px;
        text-align: left;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.87);
        line-height: 1.2;
      }
      .SafuContent {
        color: #868c9a;
        text-align: left;
        margin: 16px 0 0 0;
        font-size: 26px;
      }
      .img {
        margin-right: 15px;
        width: 100px;
        height: 100px;
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }
  }
  .collapse {
    text-align: left;
    font-size: 28px;
  }
  .Contract_img {
    display: flex;
    justify-content: space-between;
    background: #f3f3f3;
    padding: 0 28px 36px 28px;
    img {
      width: 46%;
      height: 100px;
    }
  }
  .Contract_text {
    display: flex;
    background: #2c78f8;
    padding: 54px 0;
    div {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-right: 2px solid #e5e7ed;
      img {
        width: 102px;
        height: 98px;
        display: block;
      }
      p {
        color: #fff;
        margin: 20px 0 0 0;
        font-size: 24px;
      }
    }
    div:last-child {
      border-right: 0;
    }
  }
  .trade {
    background: #fafef8;
    .title {
      font-weight: 400;
      margin-bottom: 0;
      font-size: 52px;
      padding-top: 60px;
    }
    .text {
      font-size: 24px;
      color: #868c9a;
    }
    .btn-padding {
      padding: 100px 0;
    }
    .btn {
      background: #2c78f8;
      width: 270px;
      height: 70px;
      line-height: 70px;
      color: #fff;
      text-align: center;
      font-size: 28px;
      border-radius: 6px;
      margin: 0 auto;
    }
  }
  .item-img-box {
    margin-top: 30px;
    background: #f3f3f3;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 28px;
    .item-img {
      margin-bottom: 44px;
      img {
        width: 218px;
        height: 66px;
        display: block;
      }
    }
  }
  .all-title {
    background: #f3f3f3;
    padding: 26px 36px;
    text-align: left;
    font-size: 32px;
    font-weight: 500;
    line-height: 1.2;
    color: rgb(0, 0, 0);
  }
}
</style>
