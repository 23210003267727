<template>
    <div class="pop-mask">
        <van-popup v-model="show" closeable round :style="{width: '80%'}" close-icon="close" :close="close()" close-icon-position="top-right">
            <div class="box">
                <div class="title">{{unreaddes.subject}}</div>
            <div class="conten">{{unreaddes.content}}</div>
            </div>
        </van-popup>
    </div>
</template>
<script>
import {unread} from "../request/api";
export default {
    data() {
        return {
            show:false,
            pops:0,
            unreaddes:{}
        }
    },
  
  watch: {
    jtpop: {
      handler(nval) {
        this.pops = nval;
          if(this.pops == 1){
        //   this.show = true
          unread().then((res) =>{
              this.unreaddes = res.data
              if(res.code == 0){
                   this.show = true
              }else{
                  this.show = false
              }
        })
        }
      },
      deep: true, // 深度监听
      immediate: true, //立即执行
    }
  },
    computed:{
          jtpop() {
      return this.$store.state.popstatus;
    }
    },
    methods:{
        close(){
            this.$store.commit("Uppopstatus", 0);
        }
    }
}
</script>
<style lang="less" scope>
.pop-mask{
    .box{
        padding: 28px 0;
    }
    .title{
        font-size: 36px;
    }
    .conten{
        font-size: 28px;
        text-align: left;
        padding: 0 20px;
    }
}
.night{
    .box{
        color: #fff;
    }
    .van-popup{
        background-color: #0f2441 !important;
    }
}
</style>