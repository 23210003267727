<template>
  <div>
    <van-popup
      v-model="show"
      round
      position="left"
      close-icon="close"
      closeable
      close-icon-position="top-left"
      :style="{ height: '100%', width: '80%', background: '#fafef8' }"
    >
      <div class="overlay">
        <div class="User-Info">
          <div class="id" v-if="info && info.userId">ID:{{info.userId}}</div>
          <div class="wallet">
                     <div class="assetsTitle" style="display:flex">
            <span class="mgg">{{ $t("lange.indexMoney") }}</span>
            <span>
              <div class="selector" ref="selectBox" @click.stop>
                <div class="selectd" @click="currencylis = true">
                  <span class="MoenyName">
                    <span>{{ selectdName }}</span>
                    <span class="va"
                      ><svg viewBox="0 0 24 24" class="mu-select-icon">
                        <path d="M7 10l5 5 5-5z"></path></svg
                    ></span>
                  </span>
                </div>
                <ul v-show="currencylis">
                  <li
                    v-for="(item, index) in walletList"
                    :key="index"
                    @click="SwitchCurrency(item)"
                  >
                    {{ item.name }}
                  </li>
                  <span class="up-img"></span>
                </ul>
              </div>
            </span>
          </div>
            <div @click="changeicon()"><img v-if="imgicon" src="../assets/images/show.png" alt="" /><img v-else src="../assets/images/hide.png" alt="" /></div>
          </div>
          <div class="Balance" v-if="info">
            <!-- <div v-if="imgicon">{{info.balance}}</div> -->

          <div class="assetsBalance" v-show="choseUSDT"  v-if="imgicon">≈ {{ info.balance }}</div>
          <div class="assetsBalance" v-show="choseBTC"  v-if="imgicon">≈ {{ info.btcBalance }}</div>
          <div class="assetsBalance" v-show="choseETH"  v-if="imgicon">≈ {{ info.ethBalance }}</div>
          <div class="assetsBalance" v-show="choseUSDC"  v-if="imgicon">≈ {{ info.usdcBalance }}</div>
          <div v-else>*******</div>
          </div>
          <div class="Wallet-function">
            <div class="recharge" @click="activeTabJump()">{{ $t("lange.assetsRecharge") }}</div>
            <div class="recharge" @click="activeTabJump1()">{{ $t("lange.assetsWithdraw") }}</div>
          </div>
        </div>
        <div class="mu-list">
          <div
            class="item"
            v-for="(item, index) in itemList"
            :key="index"
            @click="goToLink(index)"
          >
            <div class="item-left">
              <div class="img">
                <img :src="item.icon" alt="" />
              </div>
              <div>{{ item.name }}</div>
            </div>
            <div class="item-right">
              <div v-if="item.type == 1">
                <van-switch @change="checkedW" v-model="checkedd" size="23" />
              </div>
              <div v-if="item.type == 2">
                <div class="type-rz">
                  <div class="text" v-if="info && !info.isPrimary">{{ $t("lange.NotVerified") }}</div>
                  <div class="text" v-else>{{$t('lange.Certified')}}</div>
                  <div class="rightImg">
                    <img src="../assets/images/right.png" alt="" />
                  </div>
                </div>
              </div>
              <div v-if="item.type == 4">
                <div class="type-rz">
                  <div class="text" v-if="info && !info.isSenior">{{ $t("lange.NotVerified") }}</div>
                  <div class="text" v-else>{{$t('lange.Certified')}}</div>
                  <div class="rightImg">
                    <img src="../assets/images/right.png" alt="" />
                  </div>
                </div>
              </div>
              <div class="rightImg" v-if="item.type == 3">
                <img src="../assets/images/right.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {logout} from '../request/api'
export default {
  name: "HelloWorld",
  data() {
    return {
      nightStuas:'',
      checkedd:'',
      show: false,
      imgicon:true,
      info:{},
            choseUSDT: true,
      choseBTC: false,
      choseETH: false,
      choseUSDC: false,
            currencylis: false,
            selectdName: "USDT",
            walletList: [
        { name: "USDT" },
        { name: "BTC" },
        { name: "ETH" },
        { name: "USDC" },
      ],
      itemList: [
        {
          icon: require("../assets/images/icon1.png"),
          name: this.$t("lange.nightMode"),
          type: 1,
        },
        {
          icon: require("../assets/images/icon2.png"),
          name: this.$t("lange.Primary"),
          type: 2,
        },
        {
          icon: require("../assets/images/icon3.png"),
          name: this.$t("lange.Advanced"),
          type: 4,
        },
        {
          icon: require("../assets/images/icon4.png"),
          name: this.$t("lange.footMenuAssets"),
          type: 3,
        },
        {
          icon: require("../assets/images/icon5.png"),
          name: this.$t("lange.fexCenterMenuLanguage"),
          type: 3,
        },
        {
          icon: require("../assets/images/icon6.png"),
          name: this.$t("lange.fexCenterMenuService"),
          type: 3,
        },
        {
          icon: require("../assets/images/icon7.png"),
          name: this.$t("lange.fexLeftMenuTerms"),
          type: 3,
        },
        {
          icon: require("../assets/images/icon8.png"),
          name: this.$t("lange.fexLeftMenuLogout"),
          type: 3,
        },
        {
          icon: require("../assets/images/icon9.png"),
          name: this.$t("lange.fexLeftMenuPassword"),
          type: 3,
        },
        {
          icon: require("../assets/images/icon9.png"),
          name: this.$t("lange.SetFunTitle"),
          type: 3,
        },
      ],
    };
  },
  created() {
    this.info = this.$store.state.info == '{}'  ? this.$store.state.info : JSON.parse(localStorage.getItem('info'))
  },
  mounted() {
    this.checkedd = this.$store.state.checked ?  this.$store.state.checked : JSON.parse(localStorage.getItem("night"))
       setTimeout(()=>{
      this.info = this.$store.state.info == '{}'  ? this.$store.state.info : JSON.parse(localStorage.getItem('info'))
      // console.log(this.info)
    },1000)
  },
  methods: {
        SwitchCurrency(item) {
      this.selectdName = item.name;
      if ((item.name == "USDT")) {
        this.choseUSDT = true;
        this.choseBTC = false;
        this.choseETH = false;
        this.choseUSDC = false;
      }
      if ((item.name == "BTC")) {
        this.choseUSDT = false;
        this.choseBTC = true;
        this.choseETH = false;
        this.choseUSDC = false;
      }
      if ((item.name == "ETH")) {
        this.choseUSDT = false;
        this.choseBTC = false;
        this.choseETH = true;
        this.choseUSDC = false;
      }
      if ((item.name == "USDC")) {
        this.choseUSDT = false;
        this.choseBTC = false;
        this.choseETH = false;
        this.choseUSDC = true;
      }
      this.currencylis = false;
    },
    changeicon(){
      this.imgicon = !this.imgicon
    },
    postlogout(){
      logout().then((res) =>{
        if(res.code == 0){
        localStorage.removeItem("token"); // 移除token，跳转至登录
        localStorage.clear();
        sessionStorage.clear();
        this.$router.push({ path: "login", query: {} });
        }
      })
    },
        activeTabJump() {
      this.$router.push({ path: "recharge", query: {} });
    },
        activeTabJump1() {
      this.$router.push({ path: "assets", query: {tab:'tab2'} });
    },
    checkedW(value) {
      this.checkedd = value
      this.$store.commit('changeChecked',value);
      localStorage.setItem("night", value);
    },
    goToLink(index) {
      if (index == 0) {
      }
      if (index == 1) {
        this.$router.push({ path: "certification", query: {} });
      }
      if (index == 2) {
        this.$router.push({ path: "certificationTwo", query: {} });
      }
      if (index == 3) {
        this.$router.push({ path: "assets", query: {} });
      }
      if (index == 4) {
        this.$router.push({ path: "language", query: {} });
      }
      if (index == 5) {
        const externalUrl = this.$store.state.service;
        window.location.href = externalUrl;
      }
      if (index == 6) {
        this.$router.push({ path: "termsService", query: {} });
      }
      if (index == 7) {
        this.postlogout()
      }
      if (index == 8) {
        this.$router.push({ path: "setLoginPwd", query: {} });
      }
      if (index == 9) {
        this.$router.push({ path: "changePwd", query: {} });
      }
    },
    showPopup() {
      this.show = true;
    },
  },
  props: {
    msg: String,
  },
};
</script>


<style scoped lang="less">
// @switch-size:23px;
.overlay {
  font-size: 28px;
  height: 100%;
  .User-Info {
    // margin-top: 40px;
    text-align: left;
    padding: 100px 26px 30px 26px;
    color: #868c9a;
    font-size: 32px;
    .id {
      font-size: 60px;
      font-weight: 400;
    }
    .wallet {
      display: flex;
      // align-items: center;
      img {
        width: 44px;
        height: 44px;
        display: block;
        margin-left: 10px;
      }
    }
    .Balance {
      font-weight: 700;
      color: #000;
    }
    .Wallet-function {
      margin-top: 40px;
      display: flex;
      flex-direction: row;
      .recharge {
        margin-right: 20px;
        background: #2c78f8;
        padding: 12px 30px;
        color: #fff;
        font-size: 24px;
        border-radius: 10px;
      }
    }
  }
  .mu-list {
    padding: 16px 0;
    font-size: 28px;
    .item {
      height: 110px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: rgba(0, 0, 0, 0.87);
      padding: 0 20px 0 26px;
      &-left {
        display: flex;
        align-items: center;
        .img {
          min-width: 60px;
          img {
            width: 44px;
            height: 44px;
            display: block;
          }
        }
      }
      &-right {
        .type-rz {
          display: flex;
          align-items: center;
          .text {
            font-size: 24px;
            margin-right: 10px;
          }
        }
        .rightImg {
          width: 14px;
          height: 22px;
          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
      }
    }
  }
}
.MoenyName {
  color: #232930;
  padding: 6px;
  border-radius: 10px;
  border: 2px solid #232930;
}
.mu-select-icon {
  fill: currentColor;
  width: 48px;
  height: 48px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.mgg {
  margin-right: 10px;
}
.va {
  vertical-align: -16px;
}
.up-img {
  width: 24px;
  height: 24px;
  background-color: #232930;
  transform: rotate(45deg);
  position: absolute;
  top: -10px;
  left: 58px;
}
.mu-select-content ul {
  width: 100%;
  position: absolute;
  /* text-align: left; */
  margin: 0;
  padding: 0;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%),
    0 3px 14px 2px rgb(0 0 0 / 12%);
  background: #fff;
  top: 66px;
  z-index: 9;
  // display: none;
  text-align: center;
}
.mu-select-content ul li {
  margin: 10px 0;
}
.selector {
  margin: 0 6px;
  position: relative;
  ul {
    list-style: none;
    margin: 0;
    background: #232930;
    color: #fff;
    padding: 10px 0px 10px 10px;
    font-size: 28px;
    border-radius: 10px;
    position: absolute;
    width: 100%;
    top: 68px;
    z-index: 99;
    li {
      padding: 10px 0;
      text-decoration: none;
      list-style: none;
    }
  }
}
.night .MoenyName {
  color: #fff;
  border: 2px solid #fff;
}
</style>
