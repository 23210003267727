<template>
  <div
    id="app"
    :class="{ night: nightStuas === true, light: nightStuas === false }"
  >
    <router-view />
    <van-tabbar v-model="active" route v-if="$route.meta.showTab">
      <van-tabbar-item to="/language">
        <span>{{ $t("lange.fexCenterMenuLanguage") }}</span>
        <template #icon="Language">
          <img :src="Language.active ? icon.img : icon.img" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item @click="goToExternalLink()">
        <span>{{ $t("lange.fexCenterMenuService") }}</span>
        <template #icon="Service">
          <img :src="Service.active ? icon1.img : icon1.img" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/Trade">
        <span>{{ $t("lange.footMenuTrade") }}</span>
        <template #icon="trade">
          <img :src="trade.active ? icon2.img : icon2.img" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/assets">
        <span>{{ $t("lange.footMenuAssets") }}</span>
        <template #icon="Assets">
          <img :src="Assets.active ? icon3.img : icon3.img" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
    <pop></pop>
  </div>
</template>
<script>
import pop from "@/components/pop.vue";
import {info,CustomerService} from './request/api'
export default {
  components: {
    pop
  },
  data() {
    return {
      nightStuas: "",
      active: 0,
      icon: {
        img: require("./assets/images/lug.png"),
      },
      icon1: {
        img: require("./assets/images/kf.png"),
      },
      icon2: {
        img: require("./assets/images/zitiaoheyueguanli.png"),
      },
      icon3: {
        img: require("./assets/images/zichanshebei.png"),
      },
    };
  },
  created() {
    var value = JSON.parse(localStorage.getItem("night"));
    this.$store.commit("changeChecked", value);
    this.nightStuas = this.$store.state.checked;
          let data = {code:'CustomerService'}
    CustomerService(data).then((res) =>{
      this.$store.commit("Upservice", res.data.value);
      // console.log(this.$store.state.service)
    })
  },
  watch: {
    jtData: {
      handler(nval) {
        this.nightStuas = nval;
        // console.log(nval);
      },
      deep: true, // 深度监听
      immediate: true, //立即执行
    }
  },

  mounted() {
        if(localStorage.getItem("token")){
    info().then((res) => {
      localStorage.setItem("info", JSON.stringify(res));
      this.$store.commit("Updateinfo", res);
    });
    }
  },
  computed: {
    jtData() {
      return this.$store.state.checked;
    },

  },
  methods: {
    goToExternalLink() {
      const externalUrl = this.$store.state.service;
      window.location.href = externalUrl;
    },
  },
};
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  // color: #2c3e50;
  overflow-x: hidden;
  height: 100%;
}
</style>
