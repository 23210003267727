import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en.js'
import zhHans from './zh-CN.js'
import zhHant from './zh-TW.js'
import pt from './pt.js'
import vi from './vn.js'
import ja from './ja.js'
import de from './de.js'
import ko from './kr.js'
import th from './th.js'
import fr from './fr.js'
import es from './es.js'
import blr from './blr.js'
import ru from './ru.js'
import ms from './ms.js'

Vue.use(VueI18n)

export default new VueI18n({ 
    locale:localStorage.getItem('locale') ? localStorage.getItem('locale') : localStorage.setItem('locale','en'),
    messages:{ 
        en, 
        'zh-Hans':zhHans,
        'zh-Hant':zhHant,
        pt,
        vi,
        ja,
        de,
        ko,
        th,
        fr,
        es,
        blr,
        ru,
        ms
   }
})
