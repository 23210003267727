import request from "./request";

//k线数据
export function kliner(data) {
    return request({
     method:'POST',
     url:"/mms-api/coins/kline",
     data: data
    })
  }
//热门行情
export function hotTickers() {
    return request({
     method:'POST',
     url:"/mms-api/coins/hot/tickers",
    })
  }
//行情
export function tickers(data) {
    return request({
     method:'POST',
     url:"/mms-api/coins/tickers",
     data: data
    })
  }
//下单提交
export function transactionUser(data) {
    return request({
     method:'POST',
     url:"/mms-api/transaction_record/user/submit",
     data: data
    })
  }
//下单列表
export function transactionList(data) {
    return request({
     method:'POST',
     url:"/mms-api/transaction_record/user/list",
     data: data
    })
  }
//买入卖出
export function trade(data) {
    return request({
     method:'POST',
     url:"/mms-api/coins/trade",
     data: data
    })
  }
//获取验证码
export function captcha() {
    return request({
     method:'GET',
     url:"/mms-api/captcha",
    })
  }
//注册
export function register(data) {
    return request({
     method:'POST',
     url:"/mms-api/member/register",
     data: data
    })
  }
//登录
export function login(data) {
    return request({
     method:'POST',
     url:"/mms-api/member/email_login",
     data: data
    })
  }
//退出登录
export function logout() {
    return request({
     method:'GET',
     url:"/mms-api/member/logout",
    //  data: data
    })
  }
//用户信息
export function info() {
    return request({
     method:'GET',
     url:"/mms-api/member/info",
    })
  }
//初级认证
export function primary(data) {
    return request({
     method:'POST',
     url:"/mms-api/member/primary_auth",
     data: data
    })
  }
//高级认证
export function senior(data) {
    return request({
     method:'POST',
     url:"/mms-api/member/senior_auth",
     data: data
    })
  }
//重置登录密码
export function resetPwd(data) {
    return request({
     method:'POST',
     url:"/mms-api/member/reset_password",
     data: data
    })
  }
//设置提现密码
export function setWithdrawPwd(data) {
    return request({
     method:'POST',
     url:"/mms-api/member/set_withdraw_password",
     data: data
    })
  }
//更改提现密码
export function resetWithdrawPwd(data) {
    return request({
     method:'POST',
     url:"/mms-api/member/reset_withdraw_password",
     data: data
    })
  }
//设置提款地址
export function setWithdrawAddress(data) {
    return request({
     method:'POST',
     url:"/mms-api/member/set_withdraw_address",
     data: data
    })
  }
//获取充值列表
export function rechargeAll() {
    return request({
     method:'POST',
     url:"/mms-api/recharge_method/all",
    })
  }
//提交充值
export function userRecharge(data) {
    return request({
     method:'POST',
     url:"/mms-api/recharge_record/user_recharge",
     data:data
    })
  }
//获取充值记录
export function rechargeRecord(data) {
    return request({
     method:'POST',
     url:"/mms-api/recharge_record/get_recharge_record_by_user_id",
     data: data
    })
  }
//获取提现记录
export function withdrawalsRecord(data) {
    return request({
     method:'POST',
     url:"/mms-api/withdrawals_record/user",
     data: data
    })
  }
//提交usdt提现
export function userWithdrawals(data) {
    return request({
     method:'POST',
     url:"/mms-api/withdrawals_record/user_withdrawals",
     data:data
    })
  }
//提交银行卡提现
export function bankWithdrawals(data) {
    return request({
     method:'POST',
     url:"/mms-api/withdrawals_record/user_bank_withdrawals",
     data:data
    })
  }
//添加银行卡
export function addBank(data) {
    return request({
     method:'POST',
     url:"/mms-api/user_bank_card/add",
     data:data
    })
  }
//获取银行卡列表
export function bankCard(data) {
    return request({
     method:'POST',
     url:"/mms-api/user_bank_card/list_by_user_id",
     data:data
    })
  }
//上传
export function fileUpload(data) {
    return request({
    headers:{'Content-Type':'multipart/form-data'},
     method:'POST',
     url:"/fms-api/cloud_file/upload",
     data: data
    })
  }
//获取客服信息
export function CustomerService(data) {
    return request({
     method:'POST',
     url:"/mms-api/settings/code",
     data: data
    })
  }
//轮播图
export function banner(data) {
    return request({
     method:'POST',
     url:"/mms-api/banner/list",
     data: data
    })
  }
//获取弹窗
export function unread() {
    return request({
     method:'POST',
     url:"/mms-api/mail_box/user/get-unread",
    })
  }
//获取币币兑换汇率
export function bbexchange(data) {
    return request({
     method:'POST',
     url:"/mms-api/public/exchange_rate",
     data: data
    })
  }
//提交币币兑换
export function postexchange(data) {
  return request({
   method:'POST',
   url:"/mms-api/currency_exchange_records/exchange",
   data: data
  })
}


// export function GetOrder(pages,id) {
//     return request({
//       method:'get',
//       params: {
//           page:pages.page,
//           limit:pages.limit,
//           id:id,
//       },
//       url:`/order/list`,
//     })
//   }

// export function PostAddinsert(id) {
//     return request({
//       method:'GET',
//       url:`/collect/insert?id=${id} `,
//     })
//   }