import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  //主页
  {
    path: '/',
    name: 'home',
    component: home,
    meta:{
      showTab:true
    }
  },
  //交易页面
  {
    path: '/trade',
    name: 'trade',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/trade.vue'),
    meta:{
      showTab:true
    }
  },
  //钱包
  {
    path: '/assets',
    name: 'assets',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/assets.vue'),
    // meta:{
    //   showTab:true
    // }
  },
  //选择语言
  {
    path: '/language',
    name: 'language',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/language.vue'),
    // meta:{
    //   showTab:true
    // }
  },
  //初级认证
  {
    path: '/certification',
    name: 'certification',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/certification.vue'),
  },
  //高级认证
  {
    path: '/certificationTwo',
    name: 'certificationTwo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/certificationTwo.vue'),
  },
  //设置密码
  {
    path: '/setLoginPwd',
    name: 'setLoginPwd',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/setLoginPwd.vue'),
  },
  //设置资金密码
  {
    path: '/changePwd',
    name: 'changePwd',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/changePwd.vue'),
  },
  //充值
  {
    path: '/recharge',
    name: 'recharge',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/recharge.vue'),
  },
  //银行提现首页
  {
    path: '/WithdrawalBank',
    name: 'WithdrawalBank',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/WithdrawalBank.vue'),
  },
  //银行提现列表
  {
    path: '/WithdrawalBankTwo',
    name: 'WithdrawalBankTwo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/WithdrawalBankTwo.vue'),
  },
  //银行添加
  {
    path: '/addBank',
    name: 'addBank',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/addBank.vue'),
  },
  //注册
  {
    path: '/register',
    name: 'register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/register.vue'),
  },
  //登录
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/login.vue'),
  },
  //登录
  {
    path: '/termsService',
    name: 'termsService',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/termsService.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})


export default router
